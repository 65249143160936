import { t } from '@core/libs/i18n/utils'
import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

export default function usePeriodCalculations() {
  const isAddNewDocumentSidebarActive = ref(false)
  const refDocumentsListTable = ref(null)
  const tableColumns = [
    {
      key: 'n_datePost', sortable: false, label: t('Date'), thClass: 'text-center', tdClass: 'text-center',
    },
    {
      key: 'n_header', sortable: false, label: 'Описание документа', thClass: 'text-center',
    },
    {
      key: 'n_link_download', sortable: false, label: 'Ссылка', thClass: 'text-center', tdClass: 'text-center',
    },
    // { key: 'status', sortable: false, label: t('Status') },
    // { key: 'actions', label: '', sortable: false },
  ]
  const documentsStatuses = [
    { value: '0', label: 'Не опубликовано' },
    { value: '1', label: 'Опубликовано' },
    { value: '1000', label: 'Все' },
  ]
  const xparam1 = ref('1000')
  const documentsData = ref([])
  const blankDocument = {}
  const document = ref(JSON.parse(JSON.stringify(blankDocument)))
  const perPage = ref(10)
  const total = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refDocumentsListTable.value ? refDocumentsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: total.value,
    }
  })

  const grabDocumentData = doc => {
    const {
      /* eslint-disable camelcase */
      id_doc,
      n_datePost: x6datePost,
      n_header: x3header,
      n_teaser: x4teaser,
      n_link_download: x7Link,
      n_fileReal: x8file,
      /* eslint-disable camelcase */
    } = doc

    let {
      listInpt: x2inpt,
    } = doc

    if (x2inpt) {
      // eslint-disable-next-line prefer-template
      x2inpt = JSON.parse(x2inpt).map(item => item + '')
    }

    return {
      /* eslint-disable camelcase */
      id_doc,
      x2inpt,
      x3header,
      x4teaser,
      x6datePost,
      x7Link,
      x8file,
      /* eslint-disable camelcase */
    }
  }

  const fetchDocuments = () => {
    store.dispatch('app-documents/fetchDocuments', {
      xparam1: xparam1.value,
      currentPage: currentPage.value,
      offset: perPage.value * (currentPage.value - 1),
      perPage: perPage.value,
      searchQuery: searchQuery.value,
      sortby: sortBy.value,
      isSortDirDesc: isSortDirDesc.value,
    })
      .then(response => {
        const { data } = response.data
        documentsData.value = data.rows
        total.value = (data.paging[0] && data.paging[0].total) || 0
      })
      .catch(() => {
        documentsData.value = undefined
      })
  }

  const deleteDocument = id => {
    store.dispatch('app-documents/deleteDocument', id).then(res => {
      if (res) {
        fetchDocuments()
      }
    })
  }

  const updateDocumentStatus = item => {
    store.dispatch('app-documents/updateDocumentStatus', item).then(res => {
      if (res) {
        fetchDocuments()
      }
    })
  }

  const editDocument = doc => {
    isAddNewDocumentSidebarActive.value = true
    document.value = grabDocumentData(doc)
  }

  const refetchData = () => {
    fetchDocuments()
  }

  watch([currentPage, xparam1], () => {
    fetchDocuments()
  })

  return {
    isAddNewDocumentSidebarActive,
    refDocumentsListTable,
    documentsStatuses,
    xparam1,
    documentsData,
    document,
    tableColumns,
    perPage,
    currentPage,
    total,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,

    fetchDocuments,
    deleteDocument,
    updateDocumentStatus,
    editDocument,
    refetchData,
  }
}
