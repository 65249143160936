<template>
  <b-card>
    <!-- <documents-list-add-new
      :is-add-new-document-sidebar-active.sync="isAddNewDocumentSidebarActive"
      :type-inpt-all="typeInptAll"
      :document-data="document"
      @refetch-data="refetchData"
    /> -->
    <!-- <div class="mb-1">
      <b-row class="align-items-end">
        <b-col
          md="6"
        >
          <documents-list-filters
            :documents-statuses="documentsStatuses"
            :xparam1.sync="xparam1"
          />
        </b-col>
        <b-col
          class="d-flex justify-content-end"
          md="6"
        >
          <b-button
            variant="outline-primary"
            @click="isAddNewDocumentSidebarActive = true"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('Add') }}</span>
          </b-button>
        </b-col>
      </b-row>
    </div> -->
    <div class="relative">
      <b-overlay
        :show="$store.state.app.loading"
        no-wrap
        :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
      />
      <b-table
        ref="refDocumentsListTable"
        class="position-relative"
        :items="documentsData"
        responsive
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        primary-key="id"
        show-empty
        empty-text="Нет данных"
      >
        <template #cell(n_header)="data">
          <div>
            <h6 v-html="data.item.n_header" />
            <p v-html="data.item.n_teaser" />
          </div>
        </template>
        <template #cell(n_link_download)="data">
          <b-button
            target="blank"
            variant="flat-primary"
            :href="data.item.n_link_download"
          >
            <div
              class="mb-50"
              style="font-size: 24px;"
            >
              <i :class="`fas ${data.item.n_icon}`" />
            </div>
            <span class="align-middle">{{ $t('Download') }}</span>
          </b-button>
        </template>
        <!-- Column: Actions -->
        <!-- <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="editDocument(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('Edit') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.iStatus < 1"
              @click="updateDocumentStatus({
                id: data.item.id_doc,
                status: 1
              })"
            >
              <feather-icon icon="CheckSquareIcon" />
              <span class="align-middle ml-50">{{ $t('Publish') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.iStatus > 0"
              @click="updateDocumentStatus({
                id: data.item.id_doc,
                status: 0
              })"
            >
              <feather-icon icon="XSquareIcon" />
              <span class="align-middle ml-50">{{ $t('Remove from publication') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteDocument(data.item.id_doc)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t('Delete') }}</span>
            </b-dropdown-item>

          </b-dropdown>
        </template> -->
      </b-table>
    </div>
    <div>
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ dataMeta.from }} - {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
  // BDropdown,
  // BDropdownItem,
  BOverlay,
  BButton,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import useDocuments from './useDocuments'
import newsAndDocumentsStoreModule from '../../newsAndDocumentsStoreModule'
// import DocumentsListFilters from './DocumentsListFilters.vue'
// import DocumentsListAddNew from './DocumentsListAddNew.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    // BDropdown,
    // BDropdownItem,
    BOverlay,
    BButton,
    // DocumentsListFilters,
    // DocumentsListAddNew,
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-documents'
    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, newsAndDocumentsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const {
      isAddNewDocumentSidebarActive,
      refDocumentsListTable,
      documentsData,
      document,
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,

      documentsStatuses,
      xparam1,

      deleteDocument,
      updateDocumentStatus,
      fetchDocuments,
      refetchData,
      editDocument,
    } = useDocuments()

    const typeInptAll = ref([])

    fetchDocuments()

    // store.dispatch('app/fetchServiceData', { stype: 'type_inptAll' })
    //   .then(response => {
    //     const { data } = response.data
    //     typeInptAll.value = data
    //   })

    return {
      refDocumentsListTable,
      isAddNewDocumentSidebarActive,
      documentsData,
      document,
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,

      documentsStatuses,
      xparam1,
      typeInptAll,

      deleteDocument,
      updateDocumentStatus,
      refetchData,
      editDocument,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
